import React from 'react';
// import {Box} from '@mui/material';
// import orgAPI from '../apis/orgAPI';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import HerdCodeLink from '../components/HerdCodeLink';

dayjs.extend(utc);

export const getColumns = (userInfo, orgs, users) => {
  return [
    {
      field: 'datetime',
      headerName: 'Datetime',
      valueFormatter: (value) => dayjs.utc(value).local().format('M/D/YYYY, h:mm:ss A'),
      minWidth: 175,
      filterable: false
    },
    {
      field: 'herd_code',
      headerName: 'Herd Code',
      minWidth: 125,
      type: 'string',
      renderCell: (params) => <HerdCodeLink {...params}/>
    },
    {
      field: 'username',
      headerName: 'Updated By',
      minWidth: 250,
      type: 'singleSelect',
      valueOptions: users,
      filterable: userInfo.orgTypeAbbrev === 'SU' || userInfo.isAdmin
    },
    {
      field: 'organization',
      headerName: 'Organization',
      minWidth: 300,
      type: 'singleSelect',
      valueOptions: orgs,
      valueFormatter: (value) => value.label,
      filterable: userInfo.orgTypeAbbrev === 'SU'
    },
    {
      field: 'delta',
      headerName: 'Fields Changed',
      minWidth: 500,
      valueFormatter: (value) => Object.keys(JSON.parse(value)).join(', '),
      filterable: false,
      sortable: false
    }
  ];
};