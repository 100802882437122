import React from 'react';
import HerdCodeLink from '../components/HerdCodeLink';

export const columns = [
  {
    field: 'code',
    headerName: 'Code',
    minWidth: 110,
    renderCell: (params) => <HerdCodeLink {...params}/>
  },
  {
    field: 'owner_name',
    headerName: 'Owner Name',
    minWidth: 270
  },
  {
    field: 'is_active',
    headerName: 'Status',
    valueGetter: (value) => value ? 'Active' : 'Inactive',
    cellClassName: (params) => params.value === 'Active' ? 'active-herd' : 'inactive-herd',
    minWidth: 115
  },
  {
    field: 'street_address',
    headerName: 'Street Address',
    minWidth: 265
  },
  {
    field: 'city',
    headerName: 'City',
    minWidth: 175
  },
  {
    field: 'state_code',
    headerName: 'State',
    minWidth: 110
  },
  {
    field: 'zip_code',
    headerName: 'Zip Code',
    minWidth: 135
  }
];