import {MouseEvent, useContext, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {AppBar, Avatar, Box, Container, Divider, IconButton, Menu, MenuItem,
  Stack, Toolbar, Tooltip, Typography, useMediaQuery, useTheme} from '@mui/material';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import Icon from '@mdi/react';
import {mdiCow, mdiTextBoxSearchOutline} from '@mdi/js';
import styled from "styled-components";

import NDHIAIcon from '../customIcons/NDHIAIcon';
import useSession from "../hooks/user/useSession";
import {SearchContext} from "../context/SearchContext";

const superUserPages: string[] = ['Invite Users', 'Organization Portal', 'Herd Portal'];

interface NavBarProps {
  sx?: Record<string, string | number>;
}
const NavBar = ({ sx }: NavBarProps) => {
  let location = useLocation();

  const {palette, breakpoints} = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const session = useSession();
  const {userInfo, logout} = session;
  const { searchState } = useContext(SearchContext);

  const [, setSearchValue] = searchState;

  const settings = [{
    name: 'Logout',
    action: () => {
      logout();
      setSearchValue('');
      sessionStorage.clear();
    }
  }];

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

  return (
    <Box>
      <AppBar
        sx={{
          position: 'static',
          ...sx,
          backgroundColor: palette.background.default,
          color: palette.grey[700],
          m: 0,
          py: 1,
          minWidth: 320
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Link to="/herds" style={{textDecoration: 'none', color: 'inherit'}}>
              <Tooltip title="Home">
                <Box
                  sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', p: 0}}
                >
                  <NDHIAIcon />
                  <Typography
                    variant={isMobile ? 'h6' : 'h5'}
                    color={palette.grey[700]}
                    noWrap
                    component="div"
                    sx={{m: 0}}
                  >
                    Herd Portal
                  </Typography>
                </Box>
              </Tooltip>
            </Link>

            {userInfo.username && <Stack direction='row' spacing={2} sx={{flexWrap: 'wrap-reverse', justifyContent: 'end'}}>
              {/* FOR 'super users' and admins ONLY */}
              <Stack id="superUserControls" direction='row' spacing={0} sx={{mx: 'auto', alignItems: 'center'}}>
                {userInfo.isAdmin &&
                  <Link to="/users">
                    <Tooltip title="Manage Users">
                      <IconButton sx={{backgroundColor: location.pathname === '/users' ? palette.primary.light : 'inherit'}}>
                        <GroupAddOutlinedIcon sx={{height: '100%', color: palette.grey[700]}}/>
                      </IconButton>
                    </Tooltip>
                  </Link>}
                {userInfo.orgTypeAbbrev === "SU" &&
                  <Link to="/organizations">
                    <Tooltip title="Manage Organizations">
                      <IconButton sx={{backgroundColor: location.pathname === '/organizations' ? palette.primary.light : 'inherit'}}>
                        <CorporateFareOutlinedIcon sx={{height: '100%', color: palette.grey[700]}}/>
                      </IconButton>
                    </Tooltip>
                  </Link>}
                <Link to="/logs">
                  <Tooltip title="View Logs">
                    <IconButton sx={{backgroundColor: location.pathname === '/logs' ? palette.primary.light : 'inherit'}}>
                      <Icon path={mdiTextBoxSearchOutline} size={1} color={palette.grey[700]}/>
                    </IconButton>
                  </Tooltip>
                </Link>
                <Link to="/herds">
                  <Tooltip title="Manage Herds">
                    <IconButton sx={{backgroundColor: location.pathname.includes('/herds') ? palette.primary.light : 'inherit'}}>
                      <Icon path={mdiCow} size={1} color={palette.grey[700]}/>
                    </IconButton>
                  </Tooltip>
                </Link>
              </Stack>

              <Stack direction='row' spacing={2} sx={{alignItems: 'center'}}>
                {!isMobile && <Typography variant='h6' fontSize="1.1rem">
                  {userInfo.orgName}
                  {/* {activeUser?.herd?.data_access_level} */}
                </Typography>}
                <Tooltip title="User menu" >
                  <IconButton onClick={handleOpenUserMenu} sx={{p: 0, height: '100%'}}>
                    <Avatar
                      alt={userInfo.username as unknown as string}
                      sx={{backgroundColor: '#01674F', height: isMobile ? 45 : 60, width: isMobile ? 45 : 60}}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={
                    isMobile ?
                      {vertical: 'top', horizontal: 'right'}
                      :
                      {vertical: 'bottom', horizontal: 'right'}
                  }
                  keepMounted
                  transformOrigin={
                    isMobile ?
                      {vertical: 'bottom', horizontal: 'right'} :
                      {vertical: 'top', horizontal: 'right'}
                  }
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Typography sx={{px: 2, pb: 1, fontWeight: 'bold'}}>{userInfo.username}</Typography>
                  <Divider/>
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting.name}
                      onClick={() => {
                        setting.action.call(this);
                        handleCloseUserMenu();
                      }}
                      sx={{minHeight: 'unset'}}
                    >
                      <Typography sx={{textAlign: 'center'}}>{setting.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Stack>
            </Stack>}
          </Toolbar>
        </Container>
      </AppBar>
      {isMobile && <Offset/>}
    </Box>
  );
};

export default NavBar;
