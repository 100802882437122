import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Link, useTheme} from '@mui/material';

const HerdCodeLink = (params) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const herdCode = params.value.toString();
  const tokens = herdCode.split('|');

  if (tokens.length === 1) {
    return (
      <Link
        component='button'
        color={params.row.is_active === 0 ? theme.palette.secondary.dark : 'primary'}
        onClick={() => navigate(`/herds/${herdCode}`)}
      >
        {`${herdCode.slice(0, 2)}-${herdCode.slice(2, 4)}-${herdCode.slice(4, 8)}`}
      </Link>
    );
  }
  else {
    return (
      <div>{`${herdCode.slice(0, 2)}-${herdCode.slice(2, 4)}-${herdCode.slice(4, 8)}`}</div>
    );
  }
};

export default HerdCodeLink;