import axios from "axios";
import { fetchConfig } from '../util/index';
import Auth from '../auth/index';

import { HerdAgreementsObject, HerdInfoObject, HerdCharacteristics, HerdLocationObject, HerdOrgsObject }
  from "../herdportalTypes";

const HOST_URL = fetchConfig('hostUrl');

export interface HerdRequestType {
  info: HerdInfoObject | {};
  orgs?: HerdOrgsObject;
  characteristics?: HerdCharacteristics;
  agreement?: HerdAgreementsObject;
}

export interface HerdCreateRequestType {
  info: HerdInfoObject | {};
  location?: HerdLocationObject | {};
  orgs?: HerdOrgsObject;
}

interface herdAPIProps {
  getAll: () => Promise<unknown>;
  getOne: (herdCode: string) => Promise<unknown>;
  getHistory: (herdCode: string) => Promise<object[]>;
  getSearch: (searchTerm: any) => Promise<object[]>;
  create: (herdObj: HerdCreateRequestType) => Promise<unknown>;
  update: (herdCode: string, updates) => Promise<unknown>;
  destroy: (herdCode: string) => Promise<unknown>;
  getChangeLogs: (params: object) => Promise<unknown>;
}

const herdAPI: herdAPIProps = {
  getAll: async () => (await axios.get(`${HOST_URL}herd`, {
    headers: { 'Authorization': `Bearer ${await Auth.getIdToken()}` }
  })).data,

  getOne: async (herdCode) => (await axios.get(`${HOST_URL}herd/${herdCode}`, {
    headers: { 'Authorization': `Bearer ${await Auth.getIdToken()}` }
  })).data,

  getHistory: async (herdCode) => (await axios.get(`${HOST_URL}herd/${herdCode}?history=true`, {
    headers: { 'Authorization': `Bearer ${await Auth.getIdToken()}` }
  })).data,

  getSearch: async (searchTerm) => (await axios.get(`${HOST_URL}herd?search=${searchTerm}`, {
    headers: { 'Authorization': `Bearer ${await Auth.getIdToken()}` }
  })).data,

  create: async (herdObj) => {
    let postObject = {info: {...herdObj.info, ...herdObj.location}, orgs: herdObj.orgs};
    if (!postObject.info.code) delete postObject.info.code;
    return (await axios.post(`${HOST_URL}herd`, postObject, {
    headers: { 'Authorization': `Bearer ${await Auth.getIdToken()}` }
  } )).data},

  update: async (herdCode, updates) => (await axios.put(`${HOST_URL}herd/${herdCode}`, updates, {
    headers: { 'Authorization': `Bearer ${await Auth.getIdToken()}` }
  })).data,

  destroy: async (herdCode) => (await axios.delete(`${HOST_URL}herd/${herdCode}`,  {
    headers: { 'Authorization': `Bearer ${await Auth.getIdToken()}` }
  })).data,

  getChangeLogs: async (params) => (await axios.get(`${HOST_URL}herd/history`, {
    params: params
  })).data
};

export default herdAPI;