import React, {useContext, useEffect, useState} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import dayjs from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DateRangePicker} from '@mui/x-date-pickers-pro';
import {Box, Stack, useMediaQuery, useTheme} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {MessageContext} from '../context/messageContext';
import useSession from '../hooks/user/useSession';
import herdAPI from '../apis/herdAPI';
import orgAPI from '../apis/orgAPI';
import personAPI from '../apis/personAPI';
import {getColumns} from './viewLogsColumns';

const ViewLogs = () => {
  /** Setup */
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {messageState: [, setMessage]} = useContext(MessageContext);
  const {userInfo} = useSession();
  const queryClient = useQueryClient();

  /** State */
  const initialState = {
    filterModel: {items: [
        {id: 1, field: 'herd_code', operator: 'contains'},
        {id: 2, field: 'username', operator: 'is'},
        {id: 3, field: 'organization', operator: 'is'}
      ]
    },
    sortModel: [{field: 'datetime', sort: 'desc'}],
    paginationModel: {pageSize: 100, page: 0},
    fromDate: dayjs().subtract(30, 'day'),
    toDate: dayjs(),
    rowCount: 0
  };

  const getSessionState = (key) => {
    const storedValue = sessionStorage.getItem(key);
    if (storedValue) {
      if (key === 'fromDate' || key === 'toDate')
        return dayjs(storedValue);
      else if (key === 'rowCount')
        return Number(storedValue);
      else
        return JSON.parse(storedValue);
    }
    else
      return initialState[key];
  };

  const [filterModel, setFilterModel] = useState(getSessionState('filterModel'));
  const [sortModel, setSortModel] = useState(getSessionState('sortModel'));
  const [paginationModel, setPaginationModel] = useState(getSessionState('paginationModel'));
  const [fromDate, setFromDate] = useState(getSessionState('fromDate'));
  const [toDate, setToDate] = useState(getSessionState('toDate'));
  const [rowCount, setRowCount] = useState(getSessionState('rowCount'));

  /** Effects */
  useEffect(() => {
    queryClient.invalidateQueries(['getUsers']);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('filterModel', JSON.stringify(filterModel));
  }, [filterModel]);

  useEffect(() => {
    sessionStorage.setItem('sortModel', JSON.stringify(sortModel));
  }, [sortModel]);

  useEffect(() => {
    sessionStorage.setItem('paginationModel', JSON.stringify(paginationModel));
  }, [paginationModel]);

  useEffect(() => {
    sessionStorage.setItem('fromDate', fromDate.format());
  }, [fromDate]);

  useEffect(() => {
    sessionStorage.setItem('toDate', toDate.format());
  }, [toDate]);

  useEffect(() => {
    sessionStorage.setItem('rowCount', String(rowCount));
  }, [rowCount]);

  /** Queries */
  const getChangeLogs = useQuery(
    ['getChangeLogs', filterModel, paginationModel, sortModel, fromDate, toDate],
    async () => {
      const queryParams = Object.assign(
        {},
        ...filterModel.items.filter(filter => !!filter.value).map(filter => ({[filter.field]: filter.value})),
        ...sortModel.map(sort => ({[`sort_${sort.field}`]: sort.sort}))
      );
      queryParams.from_date = fromDate.format('YYYY-MM-DD');
      queryParams.to_date = toDate.format('YYYY-MM-DD');
      queryParams.timezone_offset = new Date().getTimezoneOffset();
      queryParams.page_num = paginationModel.page + 1;
      queryParams.page_size = paginationModel.pageSize;

      const response = await herdAPI.getChangeLogs(queryParams);

      return {
        count: response.count,
        results: response.results
      };
    },
    {
      onError: (error) => setMessage({type: 'error', message: error.message}),
      onSuccess: (data) => {
        if (rowCount !== data.count)
          setRowCount(data.count);
      },
      staleTime: Infinity,
      refetchInterval: 300_000,
      refetchIntervalInBackground: true
    }
  );

  const getOrgs = useQuery(
    'getOrgs',
    async () => (await orgAPI.getAll()).map(org => ({value: org.id, label: org.name})),
    {
      onError: (error) => setMessage({type: 'error', message: error.message}),
      staleTime: Infinity
    }
  );

  const getUsers = useQuery(
    'getUsers',
    async () => (await personAPI.get()).data
      .filter(user => userInfo.orgTypeAbbrev === 'SU' ? true : userInfo.isAdmin ? user.org_id === userInfo.orgId : false)
      .map(user => user.cognito_username),
    {
      onError: (error) => setMessage({type: 'error', message: error.message}),
      enabled: userInfo.orgTypeAbbrev === 'SU' || !!userInfo.isAdmin,
      staleTime: Infinity
    }
  );

  /** Component */
  return (
    <Stack direction='column' spacing={2} sx={{px: {xs: 1, md: 0}, mt: '0.5rem'}}>
      <Box sx={{width: isMobile ? '100%' : '50%'}}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            value={[fromDate, toDate]}
            onChange={([from, to]) => {
              setFromDate(from);
              setToDate(to);
            }}
            localeText={{start: 'From', end: 'To'}}
          />
        </LocalizationProvider>
      </Box>

      <Box sx={{height: isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 300px)'}}>
        <DataGridPro
          sx={{
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none'
            }
          }}
          slots={{
            headerFilterMenu: null
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'linear-progress',
              noRowsVariant: 'linear-progress'
            }
          }}
          columnHeaderHeight={35}
          disableRowSelectionOnClick
          loading={getChangeLogs.isFetching}

          columns={getColumns(userInfo, getOrgs.data, getUsers.data)}
          rows={getChangeLogs.data?.results || []}

          filterMode='server'
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          headerFilters
          disableColumnFilter
          disableColumnMenu
          headerFilterHeight={55}

          sortingMode='server'
          sortModel={sortModel}
          onSortModelChange={setSortModel}

          pagination
          paginationMode='server'
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[25, 50, 100, 500, 1000]}
          rowCount={rowCount}
        />
      </Box>
    </Stack>
  );
};

export default ViewLogs;