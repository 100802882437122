import _extends from "@babel/runtime/helpers/esm/extends";
import useEventCallback from '@mui/utils/useEventCallback';
import { unstable_useDateField as useDateField } from '@mui/x-date-pickers/DateField';
import { useControlledValueWithTimezone, useDefaultizedDateField } from '@mui/x-date-pickers/internals';
import { useValidation } from '@mui/x-date-pickers/validation';
import { validateDateRange } from "../../../validation/index.js";
import { rangeValueManager } from "../../utils/valueManagers.js";
import { excludeProps } from "./shared.js";
import { useMultiInputFieldSelectedSections } from "../useMultiInputFieldSelectedSections.js";
export const useMultiInputDateRangeField = ({
  sharedProps: inSharedProps,
  startTextFieldProps,
  unstableStartFieldRef,
  endTextFieldProps,
  unstableEndFieldRef
}) => {
  const sharedProps = useDefaultizedDateField(inSharedProps);
  const {
    value: valueProp,
    defaultValue,
    referenceDate,
    format,
    formatDensity,
    shouldRespectLeadingZeros,
    onChange,
    disabled,
    readOnly,
    selectedSections,
    onSelectedSectionsChange,
    timezone: timezoneProp,
    enableAccessibleFieldDOMStructure,
    autoFocus
  } = sharedProps;
  const {
    value,
    handleValueChange,
    timezone
  } = useControlledValueWithTimezone({
    name: 'useMultiInputDateRangeField',
    timezone: timezoneProp,
    value: valueProp,
    defaultValue,
    referenceDate,
    onChange,
    valueManager: rangeValueManager
  });
  const {
    validationError,
    getValidationErrorForNewValue
  } = useValidation({
    props: sharedProps,
    value,
    timezone,
    validator: validateDateRange,
    onError: sharedProps.onError
  });

  // TODO: Maybe export utility from `useField` instead of copy/pasting the logic
  const buildChangeHandler = index => {
    return (newDate, rawContext) => {
      const newDateRange = index === 0 ? [newDate, value[1]] : [value[0], newDate];
      const context = _extends({}, rawContext, {
        validationError: getValidationErrorForNewValue(newDateRange)
      });
      handleValueChange(newDateRange, context);
    };
  };
  const handleStartDateChange = useEventCallback(buildChangeHandler(0));
  const handleEndDateChange = useEventCallback(buildChangeHandler(1));
  const selectedSectionsResponse = useMultiInputFieldSelectedSections({
    selectedSections,
    onSelectedSectionsChange,
    unstableStartFieldRef,
    unstableEndFieldRef
  });
  const startFieldProps = _extends({
    error: !!validationError[0]
  }, startTextFieldProps, selectedSectionsResponse.start, {
    disabled,
    readOnly,
    format,
    formatDensity,
    shouldRespectLeadingZeros,
    timezone,
    value: valueProp === undefined ? undefined : valueProp[0],
    defaultValue: defaultValue === undefined ? undefined : defaultValue[0],
    onChange: handleStartDateChange,
    enableAccessibleFieldDOMStructure,
    autoFocus // Do not add on end field.
  });
  const endFieldProps = _extends({
    error: !!validationError[1]
  }, endTextFieldProps, selectedSectionsResponse.end, {
    format,
    formatDensity,
    shouldRespectLeadingZeros,
    disabled,
    readOnly,
    timezone,
    value: valueProp === undefined ? undefined : valueProp[1],
    defaultValue: defaultValue === undefined ? undefined : defaultValue[1],
    onChange: handleEndDateChange,
    enableAccessibleFieldDOMStructure
  });
  const startDateResponse = useDateField(startFieldProps);
  const endDateResponse = useDateField(endFieldProps);

  /* TODO: Undo this change when a clearable behavior for multiple input range fields is implemented */
  return {
    startDate: excludeProps(startDateResponse, ['clearable', 'onClear']),
    endDate: excludeProps(endDateResponse, ['clearable', 'onClear'])
  };
};